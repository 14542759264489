<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    
    <div class="row">
      <div class="col-md-12">
        <div v-show="isSetpBeginning">
          <h3>Animal</h3>
          <animal-detail
            :show-detail="true"
            label="Sur quel animal port l'examen clinique?"
          />
          <div class="row">
            <constante-resume
              :constante="constante"
              v-if="selectedAnimal !== null"
            />
          </div>
        </div>
        <div
          class="row"
          v-show="!isSetpBeginning"
        >
          <div class="col-md-8">
            <questionnaire-section
              v-for="(section, index) in sections"
              :key="index"
              :section="section"
            />
          </div>
          <div class="col-md-4">
            <anamnese-resume :anamnese="anamnese" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-12 text-center actions"
        style="padding-top: 30px;"
      >
        <a
          href="javascript::void(0)"
          v-if="indexSection > 0"
          @click.prevent="prevStep"
          class="btn btn-inverse"
        >Précedent</a>
        <a
          href="javascript::void(0)"
          v-if="indexSection < sections.length"
          @click.prevent="nextStep"
          class="btn btn-primary"
        >Suivant</a>
        <a
          href="javascript::void(0)"
          data-toggle="modal"
          data-target="#review-exam"
          class="btn btn-info"
          v-if="indexSection == sections.length"
        >Résumé</a>
        <a
          href="javascript::void(0)"
          @click.prevent="sendExamenClinique"
          class="btn btn-secondary"
          v-if="indexSection == sections.length"
        >Envoyer</a>
      </div>
    </div>

    <modal
      :footer="false"
      title="Résumé de l'examen clinique"
      id="review-exam"
      size="large"
    >
      <div class="row">
        <div class="col-12">
          <review-examen />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import moment from 'moment'
import navbar from '../../../components/navbar.vue'
import animalDetail from '../../../components/identification/animal/detailInform.vue'
import ConstanteResume from '../../../components/identification/constante/InlineResume.vue'
import AnamneseResume from '../../../components/sanitaire/anamnese/InlineResume.vue'
import QuestionnaireSection from '../../../components/sanitaire/questionnaire/section.vue'
import ReviewExamen from '../../../components/sanitaire/questionnaire/review.vue'
import {ADD_EXAMEN_CLINIQUE} from '../../../graphql/sanitaire'
import Modal from '../../../components/modal.vue'
export default {
    components: {navbar, QuestionnaireSection, animalDetail, ReviewExamen, Modal, ConstanteResume, AnamneseResume},
    watch: {
        selectedAnimal(){
            if(this.selectedAnimal!== null){
                this.updateReview({uid: 0, ordre: 0, libelle: 'Animal', questions: [{uid: 0, libelle: 'Code Iso', answer:  this.selectedAnimal}]})
                this.constante = [...this.constantes].filter(item => item.animal === this.selectedAnimal).sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))[0]
                this.anamnese = [...this.anamneses].filter(item => item.animal === this.selectedAnimal).sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))[0]
                this.setCurrentConstante(this.constante)
            }
        }
    },
    data(){
        return {
          navbarItems: [
            {
                libelle: 'Sanitaire'
            },
            {
                libelle: 'Examen clinique',
                route: 'examen_clinique'
            },
            {
              libelle: 'Ajout'
            }
            ],
            pageIcon: 'la-heartbeat',
            pageTitle: 'Ajouter un examen clinique',
            pageDescription: 'Nouvel examen clinique', 
            counter: 0,
            constante: null,
            anamnese: null,
            has_error: false,
            error_msg: null
        }
    },
    
    mounted(){
        this.initReview()
        this.setSectionIndex(0)
        this.setCurrentConstante(null)
    },
    methods: {
        ...mapMutations({
            setSectionIndex: 'sanitaire/SET_SECTION_INDEX',
            updateReview: 'sanitaire/UPDATE_REVIEW_EXAMEN',
            initReview: 'sanitaire/INIT_REVIEW_EXAMEN',
            setCurrentConstante: 'identification/SET_CURRENT_CONSTANTE'
        }),
        nextStep(){
            this.setSectionIndex(this.indexSection + 1)
        },
        prevStep() {
            if(this.indexSection > 0) this.setSectionIndex(this.indexSection - 1)
        },
        sendExamenClinique(){
            this.setSectionIndex(0)
            console.log(this.reviewExamen)
            let data = {
                animal: this.selectedAnimal,
                detail: JSON.stringify(this.reviewExamen)
            }
            this.$apollo.mutate({
                mutation: ADD_EXAMEN_CLINIQUE,
                variables: {
                    "examen": {
                        ...data
                    }
                },
                update: () => {
                    console.log('Add Examen clinique successfully')
                }
              }).then(() => {
                this.initReview()
                this.$router.push({name: 'examen_clinique'})
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
            
        }
    },
    computed: {
        ...mapGetters({
            selectedAnimal: 'identification/selectedAnimal',
            indexSection: 'sanitaire/indexQuestionnaire',
            sections: 'sanitaire/sections',
            questions: 'sanitaire/questions',
            responses: 'sanitaire/responses',
            reviewExamen: 'sanitaire/reviewExamen',
            constantes: 'identification/constantes',
            anamneses: 'sanitaire/anamneses',
            currentConstante: 'identification/currentConstante'
            
        }),
        isSetpBeginning(){
            return this.indexSection === 0
        },
        hasCurrentConstante(){
          return this.currentConstante !== null
        }
    }
}
</script>

<style lang="scss">
.actions{
    a{
        margin: 10px 5px;
    }
    
}
</style>