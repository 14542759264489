<template>
  <div class="row anamnese">
    <div class="col-12">
      <div class="profile-info">
        <h5>Résumé anamnese</h5>
        <small class="create-date">{{ anamnese.createdAt|showTime }}</small>
        <div class="single-profile-info status">
          <span
            class="badge"
            :class="anamnese.vaccine? 'badge-success': 'badge-danger'"
          >Vacciné</span>
          <span
            class="badge"
            :class="anamnese.vermifuge? 'badge-success': 'badge-danger'"
          >Vermifugé</span>
          <span
            class="badge"
            :class="anamnese.traitement? 'badge-success': 'badge-danger'"
          >Sous traitement</span>
        </div>
        <div class="single-profile-info">
          <h6>Motif consultation</h6>
          <p>{{ motif }}</p>
        </div>
        <div class="single-profile-info">
          <h6>Symptôme</h6>
          <p>{{ anamnese.symptome }}</p>
        </div>
        <div class="single-profile-info">
          <h6>Duré du symptôme</h6>
          <p>{{ anamnese.duration }}</p>
        </div>
        <div class="single-profile-info">
          <h6>Alimentation</h6>
          <ul>
            <li
              v-for="(a,i) in als"
              :key="i"
            >
              {{ a }}
            </li>
          </ul>
        </div>
        <div class="single-profile-info">
          <h6>Mode de vie</h6>
          <p>{{ mode }}</p>
        </div>
        <div class="single-profile-info">
          <h6>Antécedant medical</h6>
          <p>{{ anamnese.antecedant }}</p>
        </div>
        <user-inline :uid="userUid" />
      </div>
    </div>
    <!-- {{ anamnese }} -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import userInline from '../../admin/user/userInline.vue';
export default {
    props: {
        anamnese: {type: Object, required: true}
    },
    components: {userInline},
    computed:{
      ...mapGetters({
        motifs: 'sanitaire/motifConsultations',
        modes : 'sanitaire/modeVies',
        aliments: 'identification/aliments'
      }),
      userUid(){
        return (this.anamnese !== null) ? this.anamnese.createBy: null
      },
      mode(){
        if(this.anamnese !== null) {
          let m = this.modes.find(item => item.uid === this.anamnese.modeVie)
          if(m) return m.libelle
        }
        return '-'
      },
      motif(){
        if(this.anamnese !== null){
          let m = this.motifs.find(item => item.uid === this.anamnese.motif)
          if(m) return m.libelle
        }
        return '-'
      },
      als(){
        let result = []
        if(this.anamnese !== null){
          JSON.parse(this.anamnese.alimentation).forEach(item => {
            let al = this.aliments.find(i => i.uid === item)
            if(al) result.push(al.libelle)
          })
        }
        return result
      }
    }

}
</script>

<style lang="scss" scoped>
.anamnese{
  
    h5{
        text-transform: uppercase;
        font-size: 13px;
    }
    .create-date{
      position: absolute;
      top: 20px;
      right: 25px;
    }
    .status{
      span{
        margin: 5px;
      }
    }
}
</style>