<template>
  <div v-show="visible">
    <h3>{{ section.libelle }}</h3>
    <question
      @answer="answering"
      v-for="(question, index) in filtreQuestionnaires"
      :key="index"
      :question="question"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import question from './question.vue'
export default {
    props: {
        section: {type: Object, required: true}
    },
    components: {question},
    data(){
        return {
            sd: null
        }
    },
    mounted(){
        this.sd = {
            uid: this.section.uid,
            libelle: this.section.libelle,
            ordre: this.section.ordre,
            questions: []
        }
    },
    methods: {
        ...mapMutations({
            updateReview: 'sanitaire/UPDATE_REVIEW_EXAMEN'
        }),
        answering(payload){
            console.log(payload)
            let questions = this.sd.questions.filter(item => item.uid !== payload.uid)
            questions.push(payload)
            this.sd.questions = questions
            this.updateReview(this.sd)
        }
    },
    computed: {
        ...mapGetters({
            questions: 'sanitaire/questions',
            indexSection: 'sanitaire/indexQuestionnaire',
            selectedAnimal: 'identification/selectedAnimal',
            animaux: 'identification/animaux',
            responses: 'sanitaire/responses'
        }),
        animal(){
            let a = this.animaux.find(item => item.uid === this.selectedAnimal)
            if(a) return a
            return null
        },
        filtreQuestionnaires(){
            let  qts = []
            if(this.section !== null) {
                if(this.animal !== null){
                    qts =  [...this.questions].filter(item => {
                        if(item.sexe !== null && item.sexe !== 't'){
                            console.log(`Questionnaire ${item.libelle} specifique a sexe: ${item.sexe}`)
                            console.log(`Sexe de l'animal ${this.animal.name}: ${this.animal.sexe}`)
                            console.log(`Questionnaire visibilité: ${item.section === this.section.uid && this.animal.sexe === item.sexe}`)
                            return item.section === this.section.uid && this.animal.sexe === item.sexe
                        }
                        return item.section === this.section.uid
                    })
                    return qts.sort((a,b) => parseInt(a.ordre) - parseInt(b.ordre))
                }
                qts =  [...this.questions].filter(item => item.section === this.section.uid)
            }
            return qts.sort((a,b) => parseInt(a.ordre) - parseInt(b.ordre))
        },
        visible(){
            if(this.section !== null) return this.section.ordre === this.indexSection
            return false
        }
    }
}
</script>

<style>

</style>