<template>
  <div class="row constante">
    <div class="col-2">
      <img
        :src="photoCreateur"
        v-if="hasPhotoCreateur"
      >
      <i
        class="las la-user la-3x"
        v-else
      />
      <h5>{{ nameCreateur }}</h5>
      <small>{{ titreCreateur }}</small>
    </div>
    <div class="col-10">
      <div class="row">
        <div class="info">
          <h6>Température</h6>
          <span>{{ constante.temperature }} °C</span>
        </div>
        <div class="info">
          <h6>Poids</h6>
          <span>{{ constante.poid }} Kg</span>
        </div>
        <div class="info">
          <h6>Fréquence cardiaque</h6>
          <span>{{ constante.fc }}</span>
        </div>
        <div class="info">
          <h6>Fréquence respiratoire</h6>
          <span>{{ constante.fr }}</span>
        </div>
        <div class="info">
          <h6>Temps de recolorisation capilaire</h6>
          <span>{{ trc }}</span>
        </div>
      </div>
    </div>
    <div class="col-12 text-center">
      <span class="date">{{ constante.createdAt|showTime }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { TRCS } from '../../../constants/app';
export default {
    props: {
        constante: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            users: 'auth/users',
            typeComptes: 'auth/typeComptes',
        }),
        createdBy(){
            if(this.constante !== null) {
                let u = this.users.find(item => item.uid === this.constante.createBy)
                if(u) return u
            }
            return null
        },
        hasPhotoCreateur(){
            if(this.createdBy !== null) return this.createdBy.photo !== null
            return false
        },
        photoCreateur(){
            return  (this.hasPhotoCreateur) ?  this.createdBy.photo : null
        },
        nameCreateur(){
            return (this.createdBy !== null)? this.createdBy.fullname : ''
        },
        titreCreateur(){
            if(this.createdBy !== null){
                let t = this.typeComptes.find(item => item.uid === this.createdBy.typeCompte)
                if(t) return t.libelle
            }
            return '-'
        },
        trc(){
            let t = TRCS.find(item => item.value === this.constante.trc)
            if(t) return t.libelle
            return '-'
        }
    }

}
</script>

<style lang="scss" scoped>
.constante{
    width: 100% !important;
    border: solid 1px rgba($color: #BB1B1B, $alpha: 0.17);
    margin: 5px;
    padding: 20px;
    box-shadow: 2px 1px 5px rgba(0,0,0,0.17);
    .info{
        display: inline-flex;
        margin: 10px;
        box-shadow: 2px 1px 5px rgba(0,0,0,0.17);
        padding: 10px;
        border-radius: 5px;
        background: #BB1B1B;
        color: white;
        font-size: 12px;
        h6{
            color: white;
            position: relative;
            top: 20px;
            font-size: 13px;
        }
        span{
            margin-left: 10px;
            padding: 15px;
            border-radius: 50%;
            background: white;
            color: #BB1B1B;
        }
    }
    .date{
        font-size: 14px;
        color: rgba(0,0,20,0.57);
        font-weight: bold;
    }
}
</style>